import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexDaytona = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexDaytona {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Daytona/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Daytona Watches for Sale'}
      canonical={'/fine-watches/rolex/used-rolex-daytona/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Daytona Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-daytona/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-daytona-header.png"
                alt="Pre-Owned Certified Used Rolex Daytona Watches Header"
                aria-label="Used Rolex Daytona Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED ROLEX DAYTONA</h1>
            <h2>
              ROLEX DAYTONA: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES{' '}
            </h2>
            <h3>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX DAYTONA WATCHES</h3>
            <p>
              <br />
              <b>About Used Rolex Daytona Watch</b>
              <br />
              <br />
              Rolex debuted the Cosmograph Daytona as a motorsports-inspired chronograph in 1963.
              Surprisingly, the Daytona used to be one of Rolex’s slowest-selling watches and it
              wasn’t until the 1980s that interest in these chronographs took off among collectors.
              Fast-forward to today, and it’s a completely different story — vintage, new, and
              pre-owned Rolex Daytona watches are some of the most collectible and popular luxury
              watches ever made. Due to the desirability of used Rolex Daytonas, shoppers are often
              faced with shortages or massive premiums.
              <br />
              <br />
              Thankfully, as an independent Rolex Daytona dealer,{' '}
              <a href="/fine-watches/rolex/used-rolex-daytona/">www.grayandsons.com </a> benefits
              from both inventory control and price flexibility. Whether vintage, discontinued, or
              current, Gray &amp; Sons offers a large inventory of pre-owned Rolex Daytona watches,
              available in stock right now, ready to buy. Reach out to one of our
              <a href="/chat-with-rich">decision-makers </a>
              to secure a used Rolex Daytona watch before it’s too late. However, if you’ve been
              wondering, “where can I sell my used Rolex Daytona,” then visit
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com</a>
              . so one of our watch experts can get you the best cash offer today.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Daytona Watches</b>
              <br />
              <br />
              We offer certified authentic and certfied pre-owned watches. One important thing to
              consider when buying or selling pre-owned Daytona chronographs is that Rolex has made
              three main series so far:
              <br />
              4-Digit Vintage Daytona References (1963 – 1988; manual-winding; 38mm; steel or yellow
              gold): 6239, 6241, 6240, 6262, 6264, 6263, 6265, 6269, 6270
              <br />
              <br /> 5-Digit “Zenith” Daytona References (1988 – 2000; automatic Zenith El
              Primero-based Caliber 4030; steel, yellow gold, white gold, or two-tone steel/yellow
              gold): 16520, 16523, 16528, 16518, 16568, 16519, 16588, 16589, 16559, 16598
              <br />
              <br />
              6-Digit Daytona References (2000 – present; In-house-made Rolex Caliber 4130; steel,
              yellow gold, white gold, rose gold, platinum, or two-tone steel/yellow gold): 116520,
              116523, 116528, 116515, 116518, 116519, 116500, 116503, 116505, 116506, 116508,
              116509, 116568, 116578, 116588, 116589, 116595, 116598, 116599
              <br />
              <br /> You’ll often hear “Paul Newman Daytona” when discussing vintage Rolex Daytona
              watches. These are Daytona models fitted with exotic dials that were made famous by
              the actor and are the most sought-after variants of them all.
              <br />
              <br />
              <b>Why Gray &amp; Sons is The Best Source for Buying and Selling Rolex</b>
              <br />
              Gray and Sons is proud of its 42-year-history of buying and selling pre-owned Rolex
              Daytona watches — especially because our customers have dubbed us the #1 Rolex Daytona
              watch buyer and seller online and offline. In addition to our comprehensive e-commerce
              website, Gray and Sons is a physical store too, located across from the prestigious
              Bal Harbour Shops in the Surfside/Miami area. You can drop by (we are open six days a
              week) to browse our large selection of secondhand Rolex Daytona watches or take
              advantage of our independent Rolex Daytona service and repair facility. Our watch and
              jewelry experts are available in-person to help you fix, sell or buy pre-owned Rolex
              Daytona chronographs.
              <br />
              <br />
              Gray and Sons serves the Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach,
              Bal Harbour, Surfside, Sunny Isles, Aventura, Fort Lauderdale, Pinecrest, and Brickle
              areas.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex-daytona/">
                <button>SHOP ROLEX DAYTONA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX DAYTONA WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexDaytona
